import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mobile-no-validator"
export default class extends Controller {
  static targets = ["submitBtn"]
  validate(event) {
    if (event.target.value === '' || /^[\d,]+$/.test(event.target.value.replace(/\s/g, ''))) {
      event.target.classList.remove("border-red-500");
      this.enableButton();
    } else {
      event.target.classList.add("border-red-500");
      this.disableButton();
    }
  }

  enableButton() {
    this.submitBtnTarget.removeAttribute('disabled');
  }

  disableButton() {
    this.submitBtnTarget.setAttribute('disabled', 'disabled');
  }
}
