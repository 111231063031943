import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="customer-leaves"
export default class extends Controller {
  static targets = ['inputField']
  submit(event) {
    if (this.submitted) {
      return false
    }

    if (this.element.dataset.quantity != event.target.value) {
      this.element.requestSubmit();
    }
  }

  disableSubmitBtnEvent(event){

    if (this.element.dataset.quantity == this.inputFieldTarget.value) {
      event.preventDefault();
      return false;
    }

    this.submitted = true;
  }
}
