import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bulk-delivery-navigate"
export default class extends Controller {
  static targets = ['numberField', 'tabSize']
  

  navigate(event) {
    this.inputs = this.numberFieldTargets;

    const nextTabSize = parseInt(this.tabSizeTarget.dataset.tabsize)

    const currentIndex = this.inputs.indexOf(event.target);
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
      let nextIndex = currentIndex;

      if (event.key === "ArrowUp" && currentIndex > 0) {
        nextIndex -= nextTabSize;
      } else if (event.key === "ArrowDown" && currentIndex < this.inputs.length - 1) {
        nextIndex += nextTabSize;
      }
      this.inputs[nextIndex]?.focus();
    }
  }
}
