import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="employee-wallet-amount"
export default class extends Controller {
  static targets = ['amount', 'initialAmount', 'alert'];
  connect() {
  }

  walletAmountConfirmation(event){
    const newAmount = parseInt(this.amountTarget.value);
    const initialAmount = parseInt(this.initialAmountTarget.dataset.initialAmount);

    if(initialAmount != newAmount && newAmount > 0) {
      let confirmation = confirm(`Are you sure?\nWallet amount of ${initialAmount} will be replaced by ${newAmount},\nand the new wallet amount will be ${newAmount}.`);

      if (!confirmation) {
        event.preventDefault();
      }
    } else {
      this.checkAmount(event)
    }
  }

  checkAmount(event){
    const newAmount = parseInt(this.amountTarget.value);
    if (newAmount < 0 ) {
      this.alertTarget.innerHTML = "Amount must be greater than zero."
      this.amountTarget.classList.add("border-red-600");
      event.preventDefault();
    } else {
      this.alertTarget.innerHTML = ""
      this.amountTarget.classList.remove("border-red-600");    }
  }
}
